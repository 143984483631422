@font-face {
  font-family: Edmondsans;
  src: url(../assets/fonts/Edmondsans-Regular.otf) format('opentype'),
       url(../assets/fonts/Edmondsans-Bold.otf) format('opentype'),
       url(../assets/fonts/Edmondsans-Medium.otf) format('opentype');
}

// Overrides
@import "variables";

// Option A: Include all of Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

// Custom
@import "custom";
@import "menu-button";
@import "projects";
@import "name-swarm";

// ie fixes
@import "ie-fix";
