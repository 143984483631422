// Menu button
.menuBtn {
  position: fixed;
  overflow-y: visible;
  display: inline-block;
  top: 5.5vh;
  left: 2.5vw;
  width: 3.2rem;
  z-index: 50;
  cursor: pointer;
}

.z-100 {
  z-index: 100;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.hidden_line1 {
  width: 0rem;
  height: 0.2rem;
  background-color: white;
  margin-bottom: 0.3rem;
  transition: width 1s ease-out;
  -moz-transition: width 1s ease-out; /* Firefox 4 */
  -webkit-transition: width 1s ease-out; /* Safari and Chrome */
  -o-transition: width 1s ease-out; /* Opera */
  -ms-transition: width 1s ease-out; /* Explorer 10 */
}

.hidden_line2 {
  width: 0rem;
  height: 0.2rem;
  background-color: white;
  margin-bottom: 0.3rem;
  transition: width 0.5s ease-out;
  -moz-transition: width 0.5s ease-out; /* Firefox 4 */
  -webkit-transition: width 0.5s ease-out; /* Safari and Chrome */
  -o-transition: width 0.5s ease-out; /* Opera */
  -ms-transition: width 0.5s ease-out; /* Explorer 10 */
}

.hidden_line3 {
  width: 0rem;
  height: 0.2rem;
  background-color: white;
  margin-bottom: 0.3rem;
  transition: width 0.25s ease-out;
  -moz-transition: width 0.25s ease-out; /* Firefox 4 */
  -webkit-transition: width 0.25s ease-out; /* Safari and Chrome */
  -o-transition: width 0.25s ease-out; /* Opera */
  -ms-transition: width 0.25s ease-out; /* Explorer 10 */
}

.menuBtn_line1 {
  width: 3.2rem;
  height: 0.2rem;
  background-color: white;
  margin-bottom: 0.3rem;
  transition: width 1s ease-out 0.75s;
  -moz-transition: width 1s ease-out 0.75s; /* Firefox 4 */
  -webkit-transition: width 1s ease-out 0.75s; /* Safari and Chrome */
  -o-transition: width 1s ease-out 0.75s; /* Opera */
  -ms-transition: width 1s ease-out 0.75s; /* Explorer 10 */
}

.menuBtn_line2 {
  width: 3.2rem;
  height: 0.2rem;
  background-color: white;
  margin-bottom: 0.3rem;
  transition: width 0.75s ease-out 0.75s;
  -moz-transition: width 0.75s ease-out 0.75s; /* Firefox 4 */
  -webkit-transition: width 0.75s ease-out 0.75s; /* Safari and Chrome */
  -o-transition: width 0.75s ease-out 0.75s; /* Opera */
  -ms-transition: width 0.75s ease-out 0.75s; /* Explorer 10 */
}

.menuBtn_line3 {
  width: 3.2rem;
  height: 0.2rem;
  background-color: white;
  margin-bottom: 0.3rem;
  transition: width 0.5s ease-out 0.75s;
  -moz-transition: width 0.5s ease-out 0.75s; /* Firefox 4 */
  -webkit-transition: width 0.5s ease-out 0.75s; /* Safari and Chrome */
  -o-transition: width 0.5s ease-out 0.75s; /* Opera */
  -ms-transition: width 0.5s ease-out 0.75s; /* Explorer 10 */
}

.x-container {
  position: absolute;
  height: 3rem;
  width: 3rem;
  top: 5.5vh;
  left: 2.5vw;
  z-index: 50;
}

.circle {
  display: none;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-width: 3px;
  border-style: solid;
  border-color: white;
  border-radius: 100%;
  box-shadow:0px 0px 5px 2px rgba(0,0,0,0.5);
  -webkit-animation: fadeInFromNone 2s ease-out;
  -moz-animation: fadeInFromNone 2s ease-out;
  -o-animation: fadeInFromNone 2s ease-out;
  animation: fadeInFromNone 2s ease-out;
  position: relative;
}

.x {
  width: 50%;
  height: 50%;
  background: -webkit-linear-gradient(-45deg, transparent 0%, transparent 46%, white 46%,  white 56%, transparent 56%, transparent 100%), 
    -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, white 46%,  white 56%, transparent 56%, transparent 100%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.x::before {
	-ms-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.x::after {
	-ms-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.x:hover { cursor: pointer; }

.x:hover::before, .x:hover::after { display: block; }


@-webkit-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }
  40% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }
  40% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }
  40% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }
  40% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
