@import url('https://fonts.googleapis.com/css?family=Sulphur+Point&display=swap');

*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
  font-size: 20px;
}

::selection {
  background: #2ddab8;
  color: white;
}

.projects {
  height: 100vh;
}

a {
  color: #545454;
  text-decoration: none;
}

a:focus, 
a:hover {
  color: #8b8b8b;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.loading {
  padding: 10px;
  transform: translate3d(-50%, -50%, 0);
  color: #8b8b8b;
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  z-index: 5;
}

.frame {
  position: absolute;
  width: 75vw !important;
  top: 0;
  margin-left: 3rem;
  padding: 1.5rem;
  z-index: 20;
  overflow-wrap: break-word;
}

.frame__title {
  font-size: inherit;
  font-weight: normal;
  margin: 0;
}

.frame__links {
  margin: 1rem 0;
  white-space: nowrap;
}

.frame__link:not(:last-child) {
  margin-right: 1rem;
  white-space: nowrap;
}

.frame__nav .frame__link:first-child {
  margin-right: 1.5rem;
  white-space: nowrap;
}

.bottom-left {
  padding: 1.5rem;
  transform: translate3d(0, -100%, 0) !important;
}

@media screen and (min-width: 53em) {
	body {
		overflow: hidden;
	}
	.frame {
    display: grid;
    height: 100vh;
    padding: 2rem 3.7rem;
    left: 0rem;
    width: 95vw !important;
    pointer-events: none;
		align-content: space-between;
		grid-template-columns: 50% 50%;
		grid-template-rows: auto auto auto;
		grid-template-areas: 'title links'
							'... ...'
							'... nav';
	}
	.frame__title {
    grid-area: title;
    z-index: 20;
	}
	.frame__links {
		grid-area: links;
    justify-self: end;
    margin: 0;
    z-index: 20;
  }
  .frame__link {
    pointer-events: auto;
    z-index: 20;
  }
  .frame__nav {
    grid-area: nav;
		justify-self: end;
  }
  .bottom-left {
    padding: 0 0 2rem 3.7rem;
  }
}